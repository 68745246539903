<template>
  <b-modal
    id="add-contact-popup"
    :visible="visible"
    size="lg"
    ok-only
    ok-title="Сохранить"
    title="Создание контакта"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="py-2">
        <b-form-group
          label="Город"
          label-for="city"
        >
          <b-form-input
            id="city"
            v-model="contact.city"
            name="city"
          />
          <small
            v-show="errors.has('city')"
            class="text-danger"
          >
            {{ errors.first('city') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Адрес"
          label-for="address"
        >
          <b-form-input
            id="address"
            v-model="contact.address"
            name="address"
          />
          <small
            v-show="errors.has('address')"
            class="text-danger"
          >
            {{ errors.first('address') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Название"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="contact.title"
            name="title"
          />
          <small
            v-show="errors.has('title')"
            class="text-danger"
          >
            {{ errors.first('title') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Slug"
          label-for="slug"
        >
          <b-form-input
            id="slug"
            v-model="contact.slug"
            name="slug"
          />
          <small
            v-show="errors.has('slug')"
            class="text-danger"
          >
            {{ errors.first('slug') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Meta Title"
          label-for="meta_title"
        >
          <b-form-input
            id="meta_title"
            v-model="contact.meta_title"
            name="meta_title"
          />
          <small
            v-show="errors.has('meta_title')"
            class="text-danger"
          >{{ errors.first('meta_title') }}</small>
        </b-form-group>
        <b-form-group
          label="Meta H1"
          label-for="meta_h1"
        >
          <b-form-input
            id="meta_title"
            v-model="contact.meta_h1"
            name="meta_title"
          />
          <small
            v-show="errors.has('meta_h1')"
            class="text-danger"
          >{{ errors.first('meta_h1') }}</small>
        </b-form-group>
        <b-form-group
          label="Meta Description"
          label-for="meta_description"
        >
          <b-form-textarea
            id="meta_description"
            v-model="contact.meta_description"
            name="meta_description"
            rows="4"
            no-resize
          />
          <small
            v-show="errors.has('meta_description')"
            class="text-danger"
          >{{ errors.first('meta_description') }}</small>
        </b-form-group>
        <b-form-group
          label="Text"
          label-for="text"
        >
          <CKEditor v-model="contact.text" />
          <small
            v-show="errors.has('text')"
            class="text-danger"
          >{{ errors.first('text') }}</small>
        </b-form-group>
        <b-form-group
          label="Телефон"
          label-for="phone"
        >
          <b-form-input
            id="phone"
            v-model="contact.phone"
            name="phone"
          />
          <small
            v-show="errors.has('phone')"
            class="text-danger"
          >
            {{ errors.first('phone') }}
          </small>
        </b-form-group>
        <b-form-group
          label="ID"
          label-for="slug"
        >
          <b-form-input
            id="slug"
            v-model="contact.slug"
            name="slug"
          />
          <small
            v-show="errors.has('slug')"
            class="text-danger"
          >
            {{ errors.first('slug') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Класс Calltouch"
          label-for="calltouch_class"
        >
          <b-form-input
            id="calltouch_class"
            v-model="contact.calltouch_class"
            name="calltouch_class"
          />
          <small
            v-show="errors.has('calltouch_class')"
            class="text-danger"
          >
            {{ errors.first('calltouch_class') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Часы работы"
          label-for="working_hours"
        >
          <InputTime
            id="working_hours"
            :key="contact.working_hours_text"
            v-model="contact.working_hours"
            :text="contact.working_hours_text"
            name="working_hours"
          />
          <small
            v-show="errors.has('working_hours')"
            class="text-danger"
          >
            {{ errors.first('working_hours') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Ссылка на построение маршрута"
          label-for="link"
        >
          <b-form-input
            id="link"
            v-model="contact.link"
            name="link"
          />
          <small
            v-show="errors.has('link')"
            class="text-danger"
          >
            {{ errors.first('link') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Список email получателей"
          label-for="form_emails"
        >
          <select-tags
            id="form_emails"
            v-model="contact.form_emails"
            name="form_emails"
            :emails="true"
          />
          <small
            v-show="errors.has('form_emails')"
            class="text-danger"
          >
            {{ errors.first('form_emails') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Иконка для карты"
        >
          <input-file
            v-model="contact.icon_key"
            size="sm"
          />
        </b-form-group>
        <div class="row">
          <div class="col-12 col-lg-6">
            <b-form-group
              label="Широта (latitude)"
              label-for="latitude"
            >
              <b-form-input
                id="latitude"
                v-model="contact.latitude"
                name="latitude"
              />
              <small
                v-show="errors.has('latitude')"
                class="text-danger"
              >
                {{ errors.first('latitude') }}
              </small>
            </b-form-group>
          </div>
          <div class="col-12 col-lg-6">
            <b-form-group
              label="Долгота (longitude)"
              label-for="longitude"
            >
              <b-form-input
                id="longitude"
                v-model="contact.longitude"
                name="longitude"
              />
              <small
                v-show="errors.has('longitude')"
                class="text-danger"
              >
                {{ errors.first('longitude') }}
              </small>
            </b-form-group>
          </div>
        </div>
        <b-form-group
          label="Бренды, к которым привязан контакт"
          label-for="brands"
        >
          <Select2
            id="brands"
            v-model="contact.brands"
            :options="brandsOptions"
            :settings="{multiple: true}"
            name="brands"
          />
          <small
            v-show="errors.has('brands')"
            class="text-danger"
          >
            {{ errors.first('brands') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Яндекс рейтинг"
          label-for="yandex_rating"
        >
          <b-form-input
            id="yandex_rating"
            v-model="contact.yandex_rating"
            name="yandex_rating"
          />
          <small
            v-show="errors.has('yandex_rating')"
            class="text-danger"
          >
            {{ errors.first('yandex_rating') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Ссылка на отзывы яндекс"
          label-for="yandex_link"
        >
          <b-form-input
            id="yandex_link"
            v-model="contact.yandex_link"
            name="yandex_link"
          />
          <small
            v-show="errors.has('yandex_link')"
            class="text-danger"
          >
            {{ errors.first('yandex_link') }}
          </small>
        </b-form-group>
        <b-form-group
          v-for="(link, indexLink) in contact.feedback_urls"
          :key="indexLink"
          :label="link.type"
          :label-for="link.type"
        >
          <b-form-input
            :id="link.type"
            v-model="contact.feedback_urls[indexLink].url"
            :name="link.type"
            :placeholder="link.type"
          />
          <small
            v-show="errors.has(link.type)"
            class="text-danger"
          >{{ errors.first(link.type) }}</small>
        </b-form-group>
        <div>
          <div class="d-flex align-items-center">
            <b-form-checkbox
              v-model="contact.export_to_reviews_service"
              switch
            />
            <div>
              Экспортировать в сбор отзывов
            </div>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  BFormGroup, BFormInput, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'

import SelectTags from '@core/components/input/SelectTags.vue'
import InputTime from '@core/components/input/InputTime.vue'
import InputFile from '@core/components/input/InputFile.vue'
import Select2 from 'v-select2-component'
import CKEditor from 'ckeditor4-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'

import Contact from '@/api/http/models/contact/Contact'
import Master from '@/api/http/models/master/Master'
import SiteBrand from '@/api/http/models/site/brand/SiteBrand'
import TranslitRusToLat from '@core/utils/TranslitRusToLat'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    SelectTags,
    InputFile,
    InputTime,
    Select2,
    CKEditor: CKEditor.component,
  },
  mixins: [mixinErrorsParse],
  data() {
    return {
      visible: false,
      contact: {
        city: '',
        address: '',
        title: '',
        meta_title: '',
        meta_description: '',
        meta_h1: '',
        text: '',
        phone: '',
        slug: '',
        calltouch_class: '',
        yandex_rating: '',
        yandex_link: '',
        working_hours: [],
        working_hours_text: '',
        link: '',
        form_emails: [],
        icon_key: '',
        latitude: '',
        longitude: '',
        brands: [],
        feedback_urls: [],
        export_to_reviews_service: false,
      },
      brandsOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
      links: 'masters/getLinks',
    }),
  },
  async created() {
    const response = await SiteBrand.getAll(this.currentSite.slug)

    if (response.data) {
      const selectedBrands = response.data.filter(brand => brand.is_selected)
      this.brandsOptions = selectedBrands.map(brand => ({
        id: brand.slug,
        text: brand.name,
      }))
    }

    this.visible = true

    const responseLinks = await Master.getLinks()

    if (responseLinks.data) {
      this.contact.feedback_urls = responseLinks.data.map(item => {
        item.url = null
        return item
      })
    }
  },
  methods: {
    ...mapActions({
      fetchLinks: 'masters/fetchLinks',
    }),
    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },

    async handleSubmit() {
      if (this.contact.slug) {
        this.contact.slug = TranslitRusToLat(this.contact.slug)
      } else if (!this.contact.slug && this.contact.title) {
        this.contact.slug = TranslitRusToLat(this.contact.title)
      }

      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Contact.add(this.currentSite.slug, this.contact)

        if (response.status === 'success' && response.data) {
          this.$emit('added', response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Контакт добавлен',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('add-contact-popup')
          })
        }

        if (response.errors) {
          this.mixinErrorsParse(response.errors)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при добавлении контакта',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
