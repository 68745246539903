<template>
  <b-modal
    id="edit-contact-popup"
    :visible="visible"
    size="lg"
    ok-only
    ok-title="Сохранить"
    :title="`Редактирование контакта «${contact.address}»`"
    @ok="clickOnSave"
    @hidden="$emit('hidden')"
  >
    <form
      ref="form"
      @submit.stop.prevent="handleSubmit"
    >
      <div class="py-2">
        <b-form-group
          label="Город"
          label-for="city"
        >
          <b-form-input
            id="city"
            v-model="editContact.city"
            name="city"
          />
          <small
            v-show="errors.has('city')"
            class="text-danger"
          >
            {{ errors.first('city') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Адрес"
          label-for="address"
        >
          <b-form-input
            id="address"
            v-model="editContact.address"
            name="address"
          />
          <small
            v-show="errors.has('address')"
            class="text-danger"
          >
            {{ errors.first('address') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Название"
          label-for="title"
        >
          <b-form-input
            id="title"
            v-model="editContact.title"
            name="title"
          />
          <small
            v-show="errors.has('title')"
            class="text-danger"
          >
            {{ errors.first('title') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Slug"
          label-for="slug"
        >
          <b-form-input
            id="slug"
            v-model="editContact.slug"
            name="slug"
          />
          <small
            v-show="errors.has('slug')"
            class="text-danger"
          >
            {{ errors.first('slug') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Meta Title"
          label-for="meta_title"
        >
          <b-form-input
            id="meta_title"
            v-model="editContact.meta_title"
            name="meta_title"
          />
          <small
            v-show="errors.has('meta_title')"
            class="text-danger"
          >{{ errors.first('meta_title') }}</small>
        </b-form-group>
        <b-form-group
          label="Meta H1"
          label-for="meta_h1"
        >
          <b-form-input
            id="meta_title"
            v-model="editContact.meta_h1"
            name="meta_title"
          />
          <small
            v-show="errors.has('meta_h1')"
            class="text-danger"
          >{{ errors.first('meta_h1') }}</small>
        </b-form-group>
        <b-form-group
          label="Meta Description"
          label-for="meta_description"
        >
          <b-form-textarea
            id="meta_description"
            v-model="editContact.meta_description"
            name="meta_description"
            rows="4"
            no-resize
          />
          <small
            v-show="errors.has('meta_description')"
            class="text-danger"
          >{{ errors.first('meta_description') }}</small>
        </b-form-group>
        <b-form-group
          label="Text"
          label-for="text"
        >
          <CKEditor v-model="editContact.text" />
          <small
            v-show="errors.has('text')"
            class="text-danger"
          >{{ errors.first('text') }}</small>
        </b-form-group>
        <b-form-group
          label="Телефон"
          label-for="phone"
        >
          <b-form-input
            id="phone"
            v-model="editContact.phone"
            name="phone"
          />
          <small
            v-show="errors.has('phone')"
            class="text-danger"
          >
            {{ errors.first('phone') }}
          </small>
        </b-form-group>
        <b-form-group
          label="ID"
          label-for="slug"
        >
          <b-form-input
            id="slug"
            v-model="editContact.slug"
            name="slug"
          />
          <small
            v-show="errors.has('slug')"
            class="text-danger"
          >
            {{ errors.first('slug') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Класс Calltouch"
          label-for="calltouch_class"
        >
          <b-form-input
            id="calltouch_class"
            v-model="editContact.calltouch_class"
            name="calltouch_class"
          />
          <small
            v-show="errors.has('calltouch_class')"
            class="text-danger"
          >
            {{ errors.first('calltouch_class') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Часы работы"
          label-for="working_hours"
        >
          <InputTime
            id="working_hours"
            :key="editContact.working_hours_text"
            v-model="editContact.working_hours"
            :text="editContact.working_hours_text"
            name="working_hours"
          />
          <small
            v-show="errors.has('working_hours')"
            class="text-danger"
          >
            {{ errors.first('working_hours') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Ссылка на построение маршрута"
          label-for="link"
        >
          <b-form-input
            id="link"
            v-model="editContact.link"
            name="link"
          />
          <small
            v-show="errors.has('link')"
            class="text-danger"
          >
            {{ errors.first('link') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Список email получателей"
          label-for="form_emails"
        >
          <select-tags
            id="form_emails"
            v-model="editContact.form_emails"
            name="form_emails"
            :emails="true"
          />
          <small
            v-show="errors.has('form_emails')"
            class="text-danger"
          >
            {{ errors.first('form_emails') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Иконка для карты"
        >
          <input-file
            v-model="editContact.icon_key"
            size="sm"
          />
        </b-form-group>
        <b-form-group
          label="Широта (latitude)"
          label-for="latitude"
        >
          <b-form-input
            id="latitude"
            v-model="editContact.latitude"
            name="latitude"
          />
          <small
            v-show="errors.has('latitude')"
            class="text-danger"
          >
            {{ errors.first('latitude') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Долгота (longitude)"
          label-for="longitude"
        >
          <b-form-input
            id="longitude"
            v-model="editContact.longitude"
            name="longitude"
          />
          <small
            v-show="errors.has('longitude')"
            class="text-danger"
          >
            {{ errors.first('longitude') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Бренды, к которым привязан контакт"
          label-for="brands"
        >
          <Select2
            id="brands"
            v-model="editContact.brands"
            :options="brandsOptions"
            :settings="{multiple: true}"
            name="brands"
          />
          <small
            v-show="errors.has('brands')"
            class="text-danger"
          >
            {{ errors.first('brands') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Яндекс рейтинг"
          label-for="yandex_rating"
        >
          <b-form-input
            id="yandex_rating"
            v-model="editContact.yandex_rating"
            name="yandex_rating"
          />
          <small
            v-show="errors.has('yandex_rating')"
            class="text-danger"
          >
            {{ errors.first('yandex_rating') }}
          </small>
        </b-form-group>
        <b-form-group
          label="Ссылка на отзывы яндекс"
          label-for="yandex_link"
        >
          <b-form-input
            id="yandex_link"
            v-model="editContact.yandex_link"
            name="yandex_link"
          />
          <small
            v-show="errors.has('yandex_link')"
            class="text-danger"
          >
            {{ errors.first('yandex_link') }}
          </small>
        </b-form-group>
        <b-form-group
          v-for="(link, indexLink) in editContact.feedback_urls"
          :key="indexLink"
          :label="link.type"
          :label-for="link.type"
        >
          <b-form-input
            :id="link.type"
            v-model="editContact.feedback_urls[indexLink].url"
            :name="link.type"
            :placeholder="link.type"
          />
          <small
            v-show="errors.has(link.type)"
            class="text-danger"
          >{{ errors.first(link.type) }}</small>
        </b-form-group>
        <div>
          <div class="d-flex align-items-center">
            <b-form-checkbox
              v-model="editContact.export_to_reviews_service"
              switch
            />
            <div>
              Экспортировать в сбор отзывов
            </div>
          </div>
        </div>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BFormGroup, BFormInput, BFormTextarea, BFormCheckbox,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import mixinErrorsParse from '@core/mixins/ui/mixinErrorsParse'

import InputFile from '@core/components/input/InputFile.vue'
import SelectTags from '@core/components/input/SelectTags.vue'
import InputTime from '@core/components/input/InputTime.vue'
import Select2 from 'v-select2-component'
import CKEditor from 'ckeditor4-vue'

import Contact from '@/api/http/models/contact/Contact'
import SiteBrand from '@/api/http/models/site/brand/SiteBrand'
import TranslitRusToLat from '@core/utils/TranslitRusToLat'

export default {
  components: {
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormCheckbox,
    InputFile,
    SelectTags,
    InputTime,
    Select2,
    CKEditor: CKEditor.component,
  },
  mixins: [mixinErrorsParse],
  props: {
    contactId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      contact: {},
      editContact: {},
      brandsOptions: [],
    }
  },
  computed: {
    ...mapGetters({
      currentSite: 'user/currentSite',
    }),
  },
  async created() {
    const contactResponse = await Contact.getOne(this.currentSite.slug, this.contactId)
    const brandsResponse = await SiteBrand.getAll(this.currentSite.slug)

    this.contact = contactResponse.data || []

    await this.preparePostObject()

    if (brandsResponse.data) {
      const selectedBrands = brandsResponse.data.filter(brand => brand.is_selected)
      this.brandsOptions = selectedBrands.map(brand => ({
        id: brand.slug,
        text: brand.name,
      }))
    }

    this.visible = true
  },
  methods: {
    preparePostObject() {
      this.editContact = JSON.parse(JSON.stringify(this.contact))
    },

    async clickOnSave(modalEvent) {
      modalEvent.preventDefault()
      this.handleSubmit()
    },

    async handleSubmit() {
      if (this.editContact.slug) {
        this.editContact.slug = TranslitRusToLat(this.editContact.slug)
      } else if (!this.editContact.slug && this.editContact.title) {
        this.editContact.slug = TranslitRusToLat(this.editContact.title)
      }

      const validate = await this.$validator.validateAll()

      if (validate) {
        const response = await Contact.update(this.currentSite.slug, this.contact.id, this.editContact)

        if (response.status === 'success' && response.data) {
          this.$emit('updated', response.data)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Контакт обновлен',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
          this.$nextTick(() => {
            this.$bvModal.hide('edit-contact-popup')
          })
        }

        if (response.errors) {
          this.mixinErrorsParse(response.errors)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка при обновлении контакта',
              icon: 'AlertCircleIcon',
              variant: 'danger',
            },
          })
        }
      }
    },
  },
}
</script>
